body {
  background: #f8e1b6;
  margin: 0;
  padding: 0;
}

.blog-detail-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  /* color: #333333; */
}

.blog-detail-header {
  text-align: center;
}

.blog-title {
  font-size: 2.2rem;
  font-weight: bold;
  /* color: #000000; */
  margin-bottom: 10px;
}

.blog-author {
  font-size: 1rem;
  color: #777777;
  margin-bottom: 20px;
}

.blog-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.blog-meta {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  margin-bottom: 20px;
  color: #777777;
}

.blog-meta span {
  display: flex;
  align-items: center;
}

.blog-meta i {
  margin-right: 6px;
  color: #ff5722;
}

.blog-content {
  font-size: 1.1rem;
  line-height: 1.8;
  /* color: #000000; */
}

.blog-content p {
  margin: 1em 0;
}

.blog-content ol,
.blog-content ul {
  margin: 1em 0;
  padding-left: 40px;
}

.blog-content li {
  margin-bottom: 0.5em;
  list-style-position: inside; /* Ensures list items are indented correctly */
  list-style: auto;
}

.blog-content strong {
  font-weight: bold;
}

.blog-content h3 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.blog-content hr {
  margin: 2em 0;
  border: 1px solid #ddd;
}

@media (max-width: 768px) {
  .blog-detail-container {
    padding: 15px;
  }

  .blog-title {
    font-size: 1.8rem;
  }

  .blog-content {
    font-size: 1rem;
  }
}
