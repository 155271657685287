.doctor-profile {
    font-family: Arial, sans-serif;
    min-height: 100vh;
    background: linear-gradient(to bottom right, #fff7ed, #fef3c7);
    color: #333;
  }
  
  .hero {
    height: 300px;
    background-color: #c2410c;
    position: relative;
    overflow: hidden;
  }
  
  .hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('https://cdn.wallpapersafari.com/48/14/CgSFWa.jpg') center/cover no-repeat;
    opacity: 0.2;
  }
  
  .hero-content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 2rem;
    color: white;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .hero h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .hero .qualification {
    font-size: 1.25rem;
    color: #fed7aa;
    margin-bottom: 0.5rem;
  }
  
  .hero .role {
    font-size: 1.125rem;
    font-weight: 600;
    color: #ffedd5;
  }
  
  .content-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  .grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;
  }
  
  @media (min-width: 768px) {
    .grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .card {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .card-content {
    padding: 1.5rem;
  }
  
  .main-info {
    grid-column: 1 / -1;
    transform: translateY(-4rem);
  }
  
  .main-info .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  @media (min-width: 768px) {
    .main-info .card-content {
      flex-direction: row;
      align-items: flex-start;
    }
  }
  
  .profile-image {
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
  }
  
  @media (min-width: 768px) {
    .profile-image {
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }
  
  .info {
    flex: 1;
  }
  
  .badges {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .badge {
    background-color: #ffedd5;
    color: #9a3412;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
  }
  
  .contact-info {
    color: #4b5563;
  }
  
  .contact-info p {
    margin-bottom: 0.5rem;
  }
  
  .icon {
    margin-right: 0.5rem;
  }
  
  .languages {
    background: linear-gradient(to bottom right, #f97316, #c2410c);
    color: white;
  }
  
  .languages ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .languages li {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }
  
  .languages li::before {
    content: '•';
    color: #fed7aa;
    display: inline-block;
    width: 1em;
    margin-right: 0.5em;
  }
  
  .expertise-grid {
    display: flex;
    /* grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); */
    gap: 1rem;
    align-content: space-around;
    justify-content: flex-start;
    justify-items: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
  
  .expertise-item {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
  }
  
  .publications ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .publications li {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    position: relative;
    font-size: 0.9rem;
  }
  
  .publications li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    background-color: #f97316;
    border-radius: 50%;
  }
  
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #9a3412;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 768px) {
    .languages, .expertise, .publications {
      grid-column: 1 / -1;
    }
  }
  