.mt-100 {
    margin-top: 100px;
  }
  .card {
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
      0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
      0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
      0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
    border-width: 0;
    transition: all 0.2s;
  }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
  .card-header {
    display: flex;
    align-items: center;
    border-bottom-width: 1px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0.625rem;
    height: 3.5rem;
    background-color: #fff;
    border-bottom: 1px solid rgba(26, 54, 126, 0.125);
  }
  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
  }
  .flex-truncate {
    min-width: 0 !important;
  }
  .d-block {
    display: block !important;
  }
  a {
    color: #e91e63;
    text-decoration: none !important;
    background-color: transparent;
  }
  .media img {
    width: 40px;
    height: auto;
  }
  